.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: calc(10px + 2vmin);
	margin-left: auto;
	margin-right: auto;
}

.App-header,
.App-main {
	text-align: center;
}

.App-header {
	margin-top: 1.25em;
	margin-bottom: 1.25em;
}

.App-main {
	width: 100%;
	max-width: 470px;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
